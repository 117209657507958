<template>
  <deviceBorrowItem
    class="giveBack"
    loaddingClass="giveBack"
    :rowKeyList="rowKeyList"
    :keyList="keyList"
    :queryType="4"
  ></deviceBorrowItem>
</template>
<script>
import deviceBorrowItem from "@/components/deviceBorrowItem";

export default {
  name: "mindReservation",
  components: { deviceBorrowItem },
  data() {
    return {
      keyList: [
        {
          key: "orderId",
          name: "预约单号",
          space: "21px",
          float: "right",
          color: "#3E73FB",
        },
        {
          key: "orderTime",
          name: "借用时间",
          space: "21px",
          float: "right",
          color: "#3E73FB",
        },
      ],
      rowKeyList: [
        { key: "brandName", name: "品 牌", space: "21px", float: "right" },
        { key: "modelName", name: "型 号", space: "21px", float: "right" },
        { key: "code", name: this.$VUEAPPDEVICECODE(), space: "21px", float: "right" },
        { key: "label", name: "卡 片 号", space: "3px", float: "right" },
        {
          key: "categoryName",
          name: "设备分类",
          space: "21px",
          float: "right",
        },
        {
          key: "depreciationMethod",
          name: "折旧方式",
          space: "21px",
          float: "right",
        },
        {
          key: "yearLimit",
          name: "折 旧",
          space: "21px",
          float: "right",
        },
      ],
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.mindReservation {
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .list {
    height: 100%;
  }

  .emptyView {
    height: 100%;
  }

  .loadMore {
    text-align: center;
    color: $color4;
    font-size: 12px;
    padding: 12px;
  }
}

</style>
